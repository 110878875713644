<template>
	<v-container>
		<AdminDefaultHeader>{{ userName }}'s Dashboard </AdminDefaultHeader>
		<StudentDashboardTabs></StudentDashboardTabs>
	</v-container>
</template>

<script>
import StudentDashboardTabs from "../dashboard/StudentDashboardTabs.vue";
import AdminDefaultHeader from "../../../../components/AdminDefaultHeader.vue";
import { mapState } from "vuex";

export default {
	name: "StudentDashboard",
	components: {
		AdminDefaultHeader,
		StudentDashboardTabs
	},
	data() {
		return {};
	},

	computed: {
		userInitials() {
			const name = this.userName?.split(" ");
			return `${name[0].charAt(0)}${name[1] ? name[1].charAt(0) : ""}`;
		},
		...mapState({
			userName: state => state.user.firstName
		})
	}
};
</script>
